<script setup>
  const props = defineProps({
    controller: { default: true, type: Boolean },
    is: { default: 'div' }
  })

  const swiper = ref(null)
  const { arrivedState, x } = useScroll(swiper)
  const { width } = useElementSize(swiper)

  const slide = direction => {
    const scroll = swiper.value.children.length
      ? Math.round(width.value / swiper.value.children[0].offsetWidth - 0.45) * swiper.value.children[0].offsetWidth
      : 1
    swiper.value.scrollBy({
      behavior: 'smooth',
      left: scroll * (direction === 'right' ? 1 : -1)
    })
  }

  const scrollWidth = computed(() => {
    width.value
    return swiper.value ? swiper.value.scrollWidth : 0
  })

  const scrollable = computed(() => {
    return scrollWidth.value > width.value
  })

  const progress = computed(() => {
    if (!scrollable.value || !swiper.value) return 100
    const tmp = (x.value * 100) / (swiper.value.scrollWidth - width.value)
    return Math.max(Math.min(tmp, 100), 0)
  })
</script>

<template>
  <component :is="props.is">
    <slot name="start" :arrivedState="arrivedState" :progress="progress" :slide="slide" />
    <div class="relative">
      <div
        class="flex w-full snap-x snap-mandatory overflow-x-auto scrollbar-none"
        :class="scrollable ? 'justify-start' : 'justify-center'"
        ref="swiper"
      >
        <slot :arrivedState="arrivedState" :progress="progress" :slide="slide" />
      </div>
      <div class="pointer-events-none absolute inset-0 flex h-full w-full items-center" v-if="controller">
        <template v-if="scrollable">
          <transition enterFromClass="opacity-0" enterToClass="opacity-100" leaveFromClass="opacity-100" leaveToClass="opacity-0">
            <button
              class="pointer-events-auto absolute left-0 flex h-24 items-center justify-center rounded-r-xl bg-black bg-opacity-10 pl-1 pr-2 text-white backdrop-blur duration-300 hover:bg-opacity-30 hover:pl-2"
              v-if="!arrivedState.left"
              @click="slide('left')"
              aria-label="Previous"
            >
              <FontAwesomeIcon class="scale-y-150" icon="fa-solid fa-chevron-left" size="lg" />
            </button>
          </transition>
          <transition enterFromClass="opacity-0" enterToClass="opacity-100" leaveFromClass="opacity-100" leaveToClass="opacity-0">
            <button
              class="pointer-events-auto absolute right-0 flex h-24 items-center justify-center rounded-l-xl bg-black bg-opacity-10 pl-2 pr-1 text-white backdrop-blur duration-300 hover:bg-opacity-30 hover:pr-2"
              v-if="!arrivedState.right"
              @click="slide('right')"
              aria-label="Next"
            >
              <FontAwesomeIcon class="scale-y-150" icon="fa-solid fa-chevron-right" size="lg" />
            </button>
          </transition>
        </template>
      </div>
    </div>
    <slot name="end" :arrivedState="arrivedState" :progress="progress" :slide="slide" />
  </component>
</template>
